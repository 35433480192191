import React from 'react';
import cx from 'classnames';

import Highlight from './highlight';

const SuggestionItem = React.forwardRef((props) => {
  const { item, onClick } = props;
  const itemStyles = cx('focusable', 'list-item');

  return (
    <li
      role="option"
      aria-selected="false"
      className={itemStyles}
      data-suggestion={item}
      onClick={onClick}
    >
      <div data-icon="search" />
      <div className="search-suggestion">
        <Highlight suggestion={item} />
      </div>
    </li>
  );
});

export default SuggestionItem;
