import { commandList } from 'browser-plus-post-messenger';

const receiveMessage = (event) => {
  const { action, detail } = event.detail;

  const _broadcast = (eventName, details = null) => {
    const event = new CustomEvent(eventName, {
      detail: details,
    });
    window.dispatchEvent(event);
  };

  switch (action) {
    case commandList.GO_HOME_PANEL: {
      _broadcast('ui-change', { name: 'home', ...detail });
      break;
    }
    case commandList.GO_SEARCH_PANEL: {
      _broadcast('ui-change', { name: 'search', ...detail });
      break;
    }
    case commandList.POST_TOP_SITES: {
      _broadcast('topsites-change', detail);
      break;
    }
    default: {
      console.error(`[Message Receiver] ${action} not found:`, detail);
      break;
    }
  }
};

export default receiveMessage;
