import {
  FetchSuggestionsCommand,
} from 'browser-plus-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

class SuggestionHelper {
  constructor() {
    this.currentSearch = null;
  }

  fetchSuggestions(keywords) {
    return new Promise(resolve => {
      const defaultResult = {
        suggestions: [],
        websites: [],
      };

      this.currentSearch = keywords;

      if (keywords.trim() === '') {
        resolve(defaultResult);
        return;
      }

      MessageSender.send(
        new FetchSuggestionsCommand({ detail: { keywords } }),
        (success, detail) => {
          if (success && detail.suggestions && detail.websites) {
            resolve(detail);
          } else {
            resolve(defaultResult);
          }
        }
      );
    });
  }
}

// eslint-disable-next-line no-class-assign
export default (SuggestionHelper = new SuggestionHelper());
