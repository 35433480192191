import 'scss/components/top_sites.scss';

import React, { Component, createRef } from 'react';
import cx from 'classnames';
import {
  RequestTopsitesCommand,
} from 'browser-plus-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

import { sendRemoteUIReady, openURL } from '@/utils';

import SiteIcon from './site_icon';
import ShowMore from './show_more_button';

class TopSites extends Component {
  constructor(props) {
    super(props);
    this.initialized = false;
    this.elementRef = createRef();
    this.refs = {
      topsites: createRef(),
      showmore: createRef(),
    };
    this.state = {
      topsites: [],
    };
    this.getTopsites().then(topsites => {
      this.setState({ topsites });
    });
  }

  mountEventListener() {
    window.addEventListener('topsites-change', this.handleTopsitesChange);
  }

  componentDidMount() {
    this.mountEventListener();
  }

  componentDidUpdate() {
    if (!this.initialized) {
      this.initialized = true;
      sendRemoteUIReady();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('topsites', this.handleTopsitesChange);
  }

  handleClick = () => {
    const topSitesWrapper = this.refs.topsites.current;
    const showMoreWrapper = this.refs.showmore.current;
    const showMoreBtnIcon = showMoreWrapper.querySelector('[data-icon]');
    const isActive = topSitesWrapper.classList.contains('show-more');

    showMoreBtnIcon.dataset.icon = !isActive ? 'arrow-up' : 'arrow-down';
    topSitesWrapper.classList.toggle('show-more', !isActive);
  }

  handleSiteIconClick = (siteData) => {
    openURL(siteData.url);
  }

  handleTopsitesChange = (event) => {
    const { topsites } = event.detail;
    this.setState({ topsites });
  }

  // Get top sites from client
  getTopsites() {
    return new Promise(resolve => {
      MessageSender.send(
        new RequestTopsitesCommand(),
        (success, topsites) => {
          if (success) {
            resolve(topsites);
          }
        }
      );
    });
  }

  render() {
    const { enable } = this.props;
    const { topsites } = this.state;
    const topsiteStyle = cx('top-site', {
      'pointer': enable,
    });

    return (
      <div id="top-sites-container">
        <div
          id="top-sites"
          ref={this.refs.topsites}
          className={topsiteStyle}
        >
          {topsites.map(
            (result, index) => (
              <SiteIcon
                key={index}
                title={result.title}
                preload={result.preload}
                type={result.type}
                url={result.url}
                tile={result.tile}
                onClick={() => {
                  this.handleSiteIconClick(result);
                }}
              />
            )
          )}
          <ShowMore onClick={this.handleClick} ref={this.refs.showmore} />
        </div>
      </div>
    );
  }
}

export default TopSites;
