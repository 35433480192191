import React from 'react';

const ShowMore = React.forwardRef((props, ref) => {
  return (
    <div id="show-more-button" onClick={props.onClick} ref={ref}>
      <div data-icon="arrow-down" />
      <div id="show-more">
        <span id="show-more-text" data-l10n-id="show-more" />
        <span id="show-less-text" data-l10n-id="show-less" />
      </div>
    </div>
  );
});

export default ShowMore;
