import {
  OpenBrowserWindowCommand,
  RemoteUIReadyCommand,
} from 'browser-plus-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

export const openURL = (termsOrURL) => {
  MessageSender.send(
    new OpenBrowserWindowCommand({
      detail: {
        keywords: termsOrURL,
      },
    })
  );
};

export const loadJSON = (href) => {
  let xhr = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    xhr.open('GET', href, true);
    xhr.responseType = 'json';
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.onerror = () => {
      console.error(`Failed to get JSON file: ${href}`);
      reject(xhr.response);
    };
    xhr.send();
  });
};

export const sendRemoteUIReady = () => {
  const timestamp = new Date().getTime();
  const isInlineActivity = false;

  MessageSender.send(new RemoteUIReadyCommand({
    detail: {
      timestamp,
      isInlineActivity,
    },
  }));
};
