/**
 * Display website from top sites and history that is matched the search term.
 */

 import React from 'react';
 import cx from 'classnames';

 import Highlight from './highlight';

 const WebsiteItem = React.forwardRef((props) => {
   const { item, onClick } = props;
   const itemStyles = cx('result', 'focusable', 'list-item');

   return (
     <div
       className={itemStyles}
       data-url={item.url}
       onClick={onClick}
     >
       <div className="icon">
         <img src={item.tile} alt="" />
       </div>
       <div className="description">
         <span className="title" dir="auto">
           <Highlight suggestion={item.title} />
         </span>
         <small className="meta">
           <Highlight suggestion={item.url} />
         </small>
       </div>
     </div>
   );
 });

 export default WebsiteItem;
