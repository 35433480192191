export const APP_ORIGIN = 'http://browser-plus.localhost';
export const ADS_ORIGIN = 'https://ssp.kaiads.com';
// Todo: Add l10n support later
export const OPTION_MENU = {
  HOME: [
    { label: 'History', value: 'history' },
    { label: 'Downloads', value: 'downloads' },
    { label: 'Settings', value: 'settings' },
  ],
  COLLECTIONS: [
    { label: 'Edit', value: 'edit' },
  ],
  TABS: [
    { label: 'Close all tabs', value: 'closeAllTabs' },
  ]
};
