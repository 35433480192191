import 'scss/root.scss';
import 'scss/app.scss';
import 'regenerator-runtime';
import 'l10n/l10n';

import React, { Component, Fragment, createRef } from 'react';
import ReactDOM from 'react-dom';
import { MessageListener, MessageSender } from 'web-message-helper';
import cx from 'classnames';

import receiveMessage from '@/message_manager';
import { APP_ORIGIN, ADS_ORIGIN } from '@/constants';
import SuggestionHelper from '@/helpers/suggestion-helper';
import { openURL } from '@/utils';

import Search from '@/components/search';
import SearchBar from '@/components/search_bar';
import TopSites from '@/components/top_sites';

class App extends Component{
  constructor(props) {
    super(props);
    MessageSender.remoteReady = true;
    MessageSender.postFullQueue();
    this.largeTextEnabled = false;
    this.initMessenger = false;
    this.startMessengers();
    this.searchBarRef = createRef();
    this.searchRef = createRef();
    this.state = {
      searching: false,
      suggestions: [],
      websites: [],
    };
  }

  startMessengers() {
    MessageSender.validMessageOrigins = [APP_ORIGIN, ADS_ORIGIN];
    MessageSender.targetOrigin = APP_ORIGIN;
    MessageSender.start();
    MessageListener.start(receiveMessage);
  }

  updateState(newState, callback = () => {}) {
    requestAnimationFrame(() => {
      this.setState(newState, callback);
    });
  }

  resetState() {
    // Reset home panel state
    this.updateState({
      suggestions: [],
      websites: [],
      searching: false,
    });
    // Reset the search bar
    this.searchBar.reset();
  }

  handleUIChange = (event) => {
    const { detail } = event;
    const { searching } = this.state;

    switch (detail.name) {
      case 'home': {
        const { reset } = detail;

        if (reset) {
          this.resetState();
        } else if (searching) {
          this.searchBar.focus();
        }
        break;
      }
      case 'search': {
        const { keywords } = detail;

        this.searchBar.input.value = keywords || '';
        this.searchBar.focus();
        break;
      }
      default:
        console.error('This event has not been handled: ', detail);
        break;
    }
  };

  mountEventListener() {
    window.addEventListener('ui-change', this.handleUIChange);
  }

  componentDidMount() {
    this.searchBar = this.searchBarRef.current;
    this.searchPanel = this.searchRef.current;
    this.mountEventListener();
    this.fetchBannerAd();
  }

  componentWillUnmount() {
    window.removeEventListener('ui-change', this.handleUIChange);
  }

  handleSearchBarEvents = async (evt) => {
    const { input } = this.searchBar;
    const searchValue = input.value;

    switch (evt.type) {
      case 'focus': {
        const result = await SuggestionHelper.fetchSuggestions(searchValue);

        this.updateState({
          searching: true,
          suggestions: result ? result.suggestions : [],
          websites: result ? result.websites : [],
        });
        break;
      }
      case 'cancel':
        this.resetState();
        break;
      case 'keydown':
        this.openBrowserWindow(searchValue);
        break;
      default:
        break;
    }
  };

  openBrowserWindow = (searchTerm) => {
    // Open browser window
    openURL(searchTerm);
    this.resetState();
  };

  handleClickSuggestion = (evt) => {
    const { dataset } = evt.target;

    if (dataset.suggestion) {
      this.openBrowserWindow(dataset.suggestion);
    } else if (dataset.url) {
      this.openBrowserWindow(dataset.url);
    }
  };

  setSuggestions = (suggestions, websites = []) => {
    this.updateState({
      searching: true,
      suggestions,
      websites
    });
  };

  async fetchBannerAd() {
    const container = document.getElementById(`promoted-ad`);

    if (typeof getKaiAd === 'undefined') {
      await LazyLoader.load([
        'https://static.kaiads.com/ads-sdk/ads-sdk.v5.min.js'
      ]);
    }

    getKaiAd({
      publisher: 'e6dfb88f-ca58-4816-85ad-27eb07964d34',
      app: 'Browser+',
      slot: 'promotionAds',
      h: 245,
      w: 300,
      // 1 when testing the ad, 0 when in production
      test: 0,
      container,
      onerror: err => {
        console.error('Custom catch:', err);
      },
      onready: ad => {
        ad.on('click', () => {});
        ad.call('display', {
          tabindex: 1,
          navClass: 'ad-nav',
          display: 'block'
        });
      }
    });
  }

  render() {
    const { searching, suggestions, websites } = this.state;
    const viewStyle = cx('home-view', {
      'hidden': searching,
    });

    return (
      <Fragment>
        <SearchBar
          ref={this.searchBarRef}
          handleInputEvents={this.handleSearchBarEvents}
          changeSuggestions={this.setSuggestions}
        />
        <Search
          ref={this.searchRef}
          show={searching}
          suggestions={suggestions}
          websites={websites}
          select={this.handleClickSuggestion}
        />
        <div className={viewStyle}>
          <TopSites enable={!searching} />
          <div id='promoted-ad' className='hidden'/>
        </div>
      </Fragment>
    );
  }
}

navigator.mozL10n.once(() => {
  ReactDOM.render(<App />, document.body);
});
