import 'scss/components/search.scss';

import React, { Component } from 'react';
import cx from 'classnames';

import Suggestions from '@/components/suggestions';

class Search extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { show, suggestions, select, websites } = this.props;
    const styles = cx('search', {
      'hidden': !show,
    });

    return (
      <div className={styles}>
        {0 === [...suggestions, ...websites].length ? (
          <div id="search-tip">
            <p data-l10n-id="search-tip" />
          </div>
        ) : (
          <Suggestions items={suggestions} sites={websites} select={select} />
        )
        }
      </div>
    );
  }
}

export default Search;
