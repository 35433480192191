import 'scss/components/suggestions.scss';

import React, { Component, Fragment } from 'react';

import SuggestionItem from './suggestion_item';
import WebsiteItem from './website_item';

class Suggestions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { items, select, sites } = this.props;
    const lget = navigator.mozL10n.get;

    return (
      <Fragment>
        <div className="suggestion-separator">
          <kai-separator label={lget('search')} />
        </div>
        <div className="suggestions">
          <ul role="listbox">
            {items.map(
              (result) => (
                <SuggestionItem item={result} onClick={select} />
              )
            )}
          </ul>
        </div>
        { sites.length > 0 ? (
          <Fragment>
            <div className="history-separator">
              <kai-separator label={lget('website')} />
            </div>
            <div className="local">
              {sites.map(
                (result) => (
                  <WebsiteItem item={result} onClick={select} />
                )
              )}
            </div>
          </Fragment>
        ) : null }
      </Fragment>
    );
  }
}

export default Suggestions;
