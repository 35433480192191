import 'scss/components/search_bar.scss';

import React, { Component, createRef } from 'react';
import cx from 'classnames';
import { PickOptionMenuCommand } from 'browser-plus-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

import { OPTION_MENU } from '@/constants';
import SuggestionHelper from '@/helpers/suggestion-helper';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      searchInput: createRef(),
      optionMenu: createRef(),
    };
    this.state = {
      inputFocus: false,
    };
  }

  componentDidMount() {
    this.input = this.refs.searchInput.current;
    this.menu = this.refs.optionMenu.current;
    this.menu.options = OPTION_MENU.HOME;
    this.mountEventListeners();
    // Wait for shadowDOM ready.
    setTimeout(() => this.changeInputStyle(), 0);
  }

  componentWillUnmount() {
    this.unmountEventListeners();
  }

  // Handle search input events
  handleEvent = async (evt) => {
    const { handleInputEvents, changeSuggestions } = this.props;
    const { type, detail: { value } } = evt;
    let suggestions = [];
    let websites = [];


    switch (type) {
      case 'input': {
        const result = await SuggestionHelper.fetchSuggestions(value);

        suggestions = result.suggestions;
        websites = result.websites;
        changeSuggestions(suggestions, websites);
        break;
      }
      case 'keydown': {
        if (evt.key !== 'Enter') {
          return;
        }
        handleInputEvents(evt);
        changeSuggestions(suggestions, websites);
        break;
      }
      case 'clear': {
        this.input.value = '';
        changeSuggestions(suggestions, websites);
        break;
      }
      case 'cancel': {
        this.reset();
        handleInputEvents(evt);
        break;
      }
      default:
        break;
    }
  }

  handleOptionMenuClick = () => {
    this.menu.open = true;
  };

  // Pick item from option menu.
  handleOptionMenuSelect = (evt) => {
    const { detail: { selected } } = evt;

    MessageSender.send(
      new PickOptionMenuCommand({
        detail: {
          itemName: selected,
        },
      })
    );
  }

  // Focus on search input.
  focus = (evt) => {
    const { handleInputEvents } = this.props;

    requestAnimationFrame(() => {
      this.input.withcancel = true;
      this.input.setSelectionRange(0, this.input.value.length);
      this.setState({ inputFocus: true });
      evt && handleInputEvents(evt);
    });
  }

  changeInputStyle = () => {
    if (!this.input) {
      return;
    }
    const shadowInput = this.input.shadowRoot.querySelector('#textfield');
    shadowInput.style = 'padding: 0 0 0 2rem';
  };

  mountEventListeners() {
    this.input.addEventListener('input', this.handleEvent);
    this.input.addEventListener('keydown', this.handleEvent);
    this.input.addEventListener('clear', this.handleEvent);
    this.input.addEventListener('cancel', this.handleEvent);
    this.menu.addEventListener('select', this.handleOptionMenuSelect);
  }

  unmountEventListeners() {
    this.input.removeEventListener('input', this.handleEvent);
    this.input.removeEventListener('keydown', this.handleEvent);
    this.input.removeEventListener('clear', this.handleEvent);
    this.input.removeEventListener('cancel', this.handleEvent);
    this.menu.removeEventListener('select', this.handleOptionMenuSelect);
  }

  reset() {
    this.input.value = '';
    this.input.withcancel = false;
    this.setState({ inputFocus: false });
  }

  render() {
    const { inputFocus } = this.state;
    const lget = navigator.mozL10n.get;
    const headerClasses = cx('search-bar', {
      'focus': inputFocus,
    });

    if (inputFocus) {
      this.input && this.input.focus();
    } else {
      this.input && this.input.blur();
    }

    return (
      <div className={headerClasses}>
        <kai-searchbar
          x-inputmode="verbatim"
          isheader="true"
          maxlength="2000"
          placeholder={lget('search-or-type-url')}
          ref={this.refs.searchInput}
          onfocus={this.focus}
        />
        <div
          className="option-menu-button"
          data-icon="menu"
          onClick={this.handleOptionMenuClick}
        >
          <kai-optionmenu ref={this.refs.optionMenu} />
        </div>
      </div>
    );
  }
}

export default SearchBar;
