import React, { Fragment } from 'react';

import SuggestionHelper from '@/helpers/suggestion-helper';

const Highlight = React.memo(({ suggestion }) => {
  const highlight = SuggestionHelper.currentSearch;
  const substrings = suggestion.split(highlight);

  return (
    <Fragment>
      {substrings.map((str, index) => {
        if (index < substrings.length - 1) {
          return (
            <Fragment>
              {str}
              <span className="highlight">{highlight}</span>
            </Fragment>
          );
        }
        return <Fragment>{str}</Fragment>;
      })}
    </Fragment>
  );
});

export default Highlight;
