import React from 'react';

const SiteIcon = React.memo(({
  preload = true,
  type = 'topsites',
  title,
  url,
  tile,
  onClick,
}) => {
  return (
    <div
      className="topsites"
      role="link"
      tabIndex="0"
      data-type={type}
      data-preload={preload}
      data-title={title}
      data-url={url}
      onClick={onClick}
    >
      <div className="favicon">
        <img src={tile} alt="" />
      </div>
      <div className="description">
        <div className="title" dir="auto">{title}</div>
      </div>
    </div>
  );
});

export default SiteIcon;
